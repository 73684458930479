import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { removeHTMLTags } from '../common/utils'
import { ResourceSetContext } from '../context/ResourceSetContext'
import { TEMPLATE_TYPES } from '../common/constants'

const HelmetWrapper = (props) => {
  const { title, description, image, altText, url, keywords, noFollow, noIndex, schema } = props

  var he = require('he')
  const context = useContext(ResourceSetContext)
  const globalRS = context.getResourcesForComponent('global')
  const schemaJsonLd = getJsonLd({ ...props, ...globalRS })

  return (
    <Helmet
      defaultTitle={title ? `${he.decode(removeHTMLTags(title))} | ${globalRS.websiteName}` : globalRS.websiteName}
      htmlAttributes={{ lang: globalRS.language }}
    >
      <link rel="canonical" href={url} />
      <meta name="docsearch:version" content="2.0" />
      <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={globalRS.projectType} />
      <meta property="og:site_name" content={globalRS.websiteName} />
      <meta property="og:image" itemprop="image" content={image || globalRS.image} />

      <meta property="og:image:alt" content={altText || globalRS.altText} />
      <meta property="og:title" content={title && `${title} | ${globalRS.websiteName}`} />
      <meta property="og:description" content={description || globalRS.description} name="og:description" />
      <meta property="description" content={description || globalRS.description} name="description" />
      <meta name="keywords" content={keywords} />
      <meta property="twitter:title" name="twitter:title" content={title && `${title} | ${globalRS.websiteName}`} />
      <meta property="twitter:description" name="twitter:description" content={description} />
      <meta property="twitter:image" name="twitter:image" content={image || globalRS.image} />
      <meta property="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" name="twitter:site" content={globalRS.websiteName} />

      {schemaJsonLd && <script type="application/ld+json">{JSON.stringify(schemaJsonLd)}</script>}
      {noIndex && <meta name="robots" content="noindex" />}
      {noFollow && <meta name="robots" content="nofollow" />}
    </Helmet>
  )
}

export const getJsonLd = (seoProps) => {
  switch (seoProps.templateType) {
    case TEMPLATE_TYPES.HOME: {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        '@id': '',
        isPartOf: {
          '@type': 'WebSite',
          url: seoProps.websiteURL,
          '@id': '/#this-site',
          publisher: { '@id': '/#this-org' },
        },
        publisher: {
          '@type': 'Corporation',
          '@id': '/#this-org',
          name: seoProps.websiteName,
          legalName: seoProps.websiteName,
          url: seoProps.websiteURL,
          image: seoProps.image,
          logo: seoProps.logo,
          telephone: seoProps.telephone,
          email: seoProps.email,
          // foundingDate: seoProps.foundingDate,
          founders: [
            {
              '@type': 'Corporation',
              name: seoProps.websiteName,
            },
          ],
          address: [
            {
              '@type': 'PostalAddress',
              streetAddress: seoProps.streetAddress,
              addressLocality: seoProps.addressLocality,
              addressRegion: seoProps.addressRegion,
              postalCode: seoProps.postalCode,
              addressCountry: seoProps.addressCountry,
            },
          ],
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer support',
            telephone: seoProps.telephone,
            email: seoProps.email,
          },
          sameAs: [seoProps.facebook, seoProps.instagram, seoProps.youtube, seoProps.vimeo],
        },
      }
    }

    case TEMPLATE_TYPES.PAGE: {
      // Ensure 'seoProps.parent' and 'seoProps.parent.structuredData' exist
      if (seoProps.parent && seoProps.parent.structuredData && seoProps.parent.structuredData.schema) {
        // Now, check for 'mainEntity' presence and handle it as an array
        if (Array.isArray(seoProps.parent.structuredData.schema.mainEntity)) {
          const mainEntities = seoProps.parent.structuredData.schema.mainEntity.map((entity) => ({
            '@type': entity._type,
            name: entity.name,
            acceptedAnswer: {
              '@type': entity.acceptedAnswer._type,
              text: entity.acceptedAnswer.text,
            },
          }))

          return {
            '@context': seoProps.parent.structuredData.schema._context,
            '@type': seoProps.parent.structuredData.schema._type,
            mainEntity: mainEntities,
          }
        }
      }

      if (seoProps.price) {
        return [
          {
            '@context': 'https://schema.org',
            '@type': 'Trip',
            name: seoProps.name,
            url: seoProps.url,
            image: seoProps.image,
            description: seoProps.description,
            offers: {
              '@type': 'Offer',
              price: seoProps.price,
              priceCurrency: seoProps.priceCurrency,
            },
          },
        ]
      } else return null
    }

    case TEMPLATE_TYPES.POST_ARCHIVE: {
      return [
        {
          '@context': 'http://schema.org',
          '@type': 'Article',
          url: seoProps.url,
          headline: seoProps.title,
          image: [seoProps.image],
          datePublished: seoProps.publishDate,
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: seoProps.category ? seoProps.breadcrumb : 'Home',
              item: seoProps.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: seoProps.category ? seoProps.category : seoProps.breadcrumb,
              item: seoProps.url,
            },
          ],
        },
      ]
    }

    case TEMPLATE_TYPES.POST: {
      return [
        {
          '@context': 'http://schema.org',
          '@type': 'Article',
          url: seoProps.url,
          headline: seoProps.title,
          image: [seoProps.image],
          datePublished: seoProps.publishDate,
        },
        {
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: seoProps?.breadcrumb || 'Home',
              item: seoProps.origin,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: seoProps.category
                ? seoProps.category.charAt(0).toUpperCase() + seoProps.category.slice(1)
                : 'Category',
              item: seoProps.category
                ? `${seoProps.origin}/${seoProps.category}`
                : `${seoProps.origin}/${seoProps.breadcrumb.toLowerCase()}`,
            },
            {
              '@type': 'ListItem',
              position: 3,
              name: 'Article',
              item: seoProps.url,
            },
          ],
        },
      ]
    }

    case TEMPLATE_TYPES.RESPONSIBLE_TRAVEL: {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: seoProps.origin,
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Responsible Travel',
            item: `${seoProps.origin}/${seoProps.templateType}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: seoProps.title,
            item: seoProps.url,
          },
        ],
      }
    }

    case TEMPLATE_TYPES.DESTINATION: {
      return null
      // Removed Breadcrumbs Schema from pages as there are no breadcrubms on the pages
    }

    default: {
      return null
    }
  }
}

export default HelmetWrapper
